import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import animated from 'animate.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css

Vue.config.productionTip = false
Vue.use(ElementUI,animated)

router.afterEach((to,from, next) => {
  window.scrollTo(0,0)
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})