<template>
  <div class="Header-menu">
    <div class="Header-menu-black">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router text-color="#999" active-text-color="var(--theme-color)">
        <el-menu-item index="/aboutUs">About Us</el-menu-item>
        <el-submenu index="/solutions">
          <template slot="title">SOLUTIONS</template>
            <el-submenu index="2-4" class="pv">
              <template slot="title">PV Installation</template>
              <el-menu-item class="pv1" index="/solutions">Residential Solar Installation</el-menu-item>
              <el-menu-item index="/solutions/commercial">Commercial Solar Installation</el-menu-item>
            </el-submenu>
          <el-menu-item index="/solutions/photovoltaic">Household PV Energy Storage Solution</el-menu-item>
          <el-menu-item index="/solutions/iac">Commercial & Industrial Energy Storage System</el-menu-item>
          <el-menu-item index="/solutions/microGrid">Solar Micro-grid System</el-menu-item>
        </el-submenu>
        <el-submenu index="/product">
          <template slot="title">PRODUCT</template>
          <!-- <el-menu-item index="/product">String Inverter</el-menu-item> -->
          <el-menu-item index="/product/Inverters">Energy Storage Inverter</el-menu-item>
          <el-menu-item index="/product/Batteries">Battery</el-menu-item>
          <el-menu-item index="/product/PV">PV Module</el-menu-item>
          <!-- <el-menu-item index="/product/EV">EV Charger</el-menu-item> -->
          <el-menu-item class="hidden" index="/product/details" disabled></el-menu-item>
        </el-submenu>
        <el-menu-item index="/cases">CASES</el-menu-item>
        <el-menu-item index="/FAQs">FAQs</el-menu-item>
        <el-menu-item index="/contact">CONTACT US</el-menu-item>
      </el-menu>
    </div>
    <div class="menu" @click="showAsideMenu()" type="primary">
      <img :src="require('@/assets/images/menu.png')" alt="" > 
    </div>
    <el-drawer
      :append-to-body="true"
      :visible.sync="drawer"
      size="75%"
      >
      <div class="menu-list-box">
        <li v-for="(item,index) in menus" :key="index" :index="item.path" @click="toPage1(item.path)">
          <div class="frist-menu" :class="activeTab==item.path?'activeTab':''">{{ item.name }}</div>
          <div class="secondary-menu" v-for="(item1,index) in item.children" :key="index" @click="toPage(item1.path)">
            <span :class="activeTab==item1.path?'activeTab':''">{{item1.name}}</span>
          </div>
        </li>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  data() {
    return {
      activeIndex: this.$route.meta.activeMenu || this.$route.path,
      activeTab: '/aboutUs',
      drawer: false,
      menus: [
        { name: 'ABOUT',path: '/aboutUs'},
        { name: 'SOLUTIONS',path: '/solutions',children: [{name:'Residential Solar Installation',path:'/solutions'},{name:'Commercial Solar Installation',path:'/solutions/commercial'},{name:'Household PV Energy Storage Solution',path:'/solutions/photovoltaic'},{name:'Commercial & Industrial Energy Storage System',path:'/solutions/iac'},{name:'Solar Micro-grid System',path:'/solutions/microGrid'}]},
        // { name: 'PRODUCT',path: '/product',children: [{name:'String Inverter',path:'/product'},{name:'Energy Storage Inverter',path:'/product/Inverters'},{name:'Battery',path:'/product/Batteries'},{name:'PV Module',path:'/product/PV'},{name:'EV Charger',path:'/product/EV'}]},
        { name: 'PRODUCT',path: '/product',children: [{name:'Energy Storage Inverter',path:'/product/Inverters'},{name:'Battery',path:'/product/Batteries'},{name:'PV Module',path:'/product/PV'}]},
        { name: 'CASES',path: '/cases'},
        { name: 'FAQs',path: '/FAQs'},
        { name: 'CONTACT US',path: '/contact'},
      ]
    }
  },
  watch: {
    $route(val) {
      this.activeIndex = val.meta.activeMenu || val.path
    }
  },
  methods: {
    showAsideMenu() {
      this.drawer = true
    },
    toPage(i) {
      this.$router.push({path: i})
      this.drawer = false
      this.activeTab = i
    },
    toPage1(i) {
      if((i== '/FAQs') || (i== '/contact') || (i== '/cases') || (i== '/aboutUs')) {
        this.$router.push({path: i})
        this.drawer = false
        this.activeTab = i
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Header-menu-black{
  .el-menu{
    background: none;
    width: 100%;
  }
  .el-menu.el-menu--horizontal{
    border: none;
  }
}
.Header-menu-black .el-menu--horizontal > .el-menu-item {
  letter-spacing: 0.5px;
  font-size: 13px;
  text-transform: uppercase;
  background: transparent;
}

.activeTab {
  color: var(--theme-color);
}

.menu {
  margin-left: 130px;
}

.menu-list-box {
  .frist-menu {
    margin: 10px 0;
  }
  .secondary-menu {
    color: #ccc;
    line-height: 1.5;
    font-size: 14px;
  }
}
.el-menu-item:hover {
	color:white !important;
  background: var(--theme-color) !important;
}

.pv > :hover {
  background: var(--theme-color) !important;
	color:white !important;
}

.el-submenu > :hover {
  background: var(--theme-color) !important;
	color:white !important;
}

.hidden {
  display: none;
}

@media screen and (min-width: 980px) {
  .menu {
    display: none
  }
}
@media screen and (max-width: 980px) {
  .Header-menu-black {
    display: none
  }
  .Header-menu-pc {
    display: none
  }
}

</style>

