<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
:root {
  --theme-color: #0d8b08;
  --footer-color: #298650;
  --line-color: #11e76a;
  --border-color: #2aee7b;

}
</style>