<template>
  <div class="Header">
    <div class="Header-black flexSp" :class="[isAnimate,isWhiteBg]" data-wow-iteration="infinite">
      <div class="box-center">
        <div class="Header-logo" @click="$router.push('/aboutUs')" >
          <img v-show="isWhiteLogo" class="login-Pc" :src="require('@/assets/images/logo1-pc.png')" height="40">
          <img v-show="!isWhiteLogo" class="login-Pc" :src="require('@/assets/images/logo.png')" height="40">
        </div>
        <div class="flexAlign">
          <div class="Header-menu">
            <Menu />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './menu'
import { WOW } from "wowjs";
export default {
  components: {
    Menu
  },
  data() {
    return {
      isAnimate: '',
      isWhiteBg: '',
      isWhiteLogo: true,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    var wow = new WOW({
      boxClass: "wow", //需要执行动画元素的Class
      animateClass: "animated", //animation.css动画的Class
      offset: 0, //距离可视区域多少开始执行动画
      mobile: true, //是否在移动设备执行动画
      live: true, //异步加载的内容是否有效
    });
    wow.init();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const distanceToTop = window.scrollY;
      if(distanceToTop < 80) {
        this.isAnimate = ''
        this.isWhiteBg = ''
        this.isWhiteLogo = true
      }else {
        this.isWhiteBg = 'whiteBg'
        this.isAnimate = 'animated fadeInDown'
        this.isWhiteLogo = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.Header {
  margin-bottom: 80px;
}
.Header-black{
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 20;
  border-bottom: 1px;
  background-color: rgba(17, 17, 17);
  .box-center{
    display: flex;
    justify-content: space-around;
  }
  .Header-logo{
    padding-top: 10px;
    cursor: pointer;
  }
}
.whiteBg {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
</style>