<template>
  <div class="outWrapper">
    <Header />
    <!-- <Carousel /> -->
    <router-view :key="$route.path" />
    <BackTop />
    <Footer />

  </div>
</template>

<script>
import Header from './Header'
import Footer from './Footer'
import BackTop from './BackTop'
import Carousel from './Carousel'



export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    BackTop,
    Carousel
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";
</style>
